export const ARTICLE_ACTION_BUTTON_ADDNOTE_FRAGMENT = `
  fragment articleActionButtonAddnoteFragment on Article {
    __typename
    id
    uid
    name
    images {
      schemaCode
      path
    }
  }
`;
